





















































































































































































































































































































































































































































































































































































































import { faEarthAsia } from '@fortawesome/pro-regular-svg-icons';
import axios from 'axios';
import gql from 'graphql-tag';
import { DateTime } from 'luxon';
import { i, trueDependencies } from 'mathjs';
import { project } from 'ramda';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';

import {
  ActionInput,
  ActionType,
  AssetExtractor,
  Category,
  Connection,
  ConnectionStatus,
  Contact,
  DeFi,
  DeFiCategorizationAction,
  DetailedCategorizationAction,
  DetailedCategorizationActionLine,
  DetailedCategorizationActionLineInput,
  InternalTransferCategorizationAction,
  MakeMaybe,
  Metadata,
  PercentageSplitCategorizationInput,
  Providers,
  RuleComparisonInput,
  SimpleCategorizationAction,
  SimpleSplitCategorizationAction,
  TradeCategorizationAction,
  TransferRule,
  TxnLineOperation,
  ValueExtractor,
} from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import { getAccountingProviderIcon, isAccountingProvider } from '@/utils/accountingProviders';
import { assertDefined } from '@/utils/guards';

import { MUT_SNACKBAR } from '../../store';
import UiSelect2 from '../ui/UiSelect2.vue';
import UiTextEdit from '../ui/UiTextEdit.vue';
import {
  assetExtractorTypes,
  categorization,
  coins,
  comparisonsTypes,
  directions,
  priorities,
  valueExtractorTypes,
} from './constants';
import RuleValidation from './RuleValidation.vue';
import {
  groupMetadataByType,
  mapTransferRulDataToTransferRuleInput,
  MetadataByType,
  ruleVarFactory,
  TransferRuleData,
} from './utilities';

@Component({
  apollo: {
    metadata: {
      query: gql`
        query getMetadata($orgId: ID!, $connectionId: ID, $includeDisabled: Boolean) {
          metadata(orgId: $orgId, connectionId: $connectionId, includeDisabled: $includeDisabled) {
            id
            enabled
            source
            metaType
            name
            remoteType
            connectionId
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
          connectionId: this.ruleFormData.accountingConnectionId,
          includeDisabled: false,
        };
      },
      skip() {
        return this.$store.state.currentOrg.id && this.ruleFormData.accountingConnectionId;
      },
      update(data) {
        if (!data.metadata) {
          return [];
        }

        return data.metadata.filter((m: Metadata | null) => Boolean(m));
      },
    },
    // wallets: {
    //   query: gql`
    //     query GetWallets($orgId: ID!) {
    //       wallets(orgId: $orgId) {
    //         id
    //         name
    //       }
    //     }
    //   `,
    //   variables() {
    //     return {
    //       orgId: this.$store.state.currentOrg.id,
    //     };
    //   },
    //   loadingKey: 'isLoadingWallets',
    //   errorPolicy: 'ignore',
    // },
  },
  components: {
    draggable,
    UiTextEdit,
    RuleValidation,
    UiSelect2,
  },
})
export default class CreateRuleModal extends BaseVue {
  @Prop({ required: true })
  readonly dialog!: boolean;

  @Prop({ required: true })
  readonly hide!: () => unknown;

  @Prop({ required: true })
  readonly show!: () => unknown;

  @Prop({ required: true })
  readonly rule!: TransferRule | null;

  @Prop({ required: true })
  readonly reset!: () => unknown;

  @Prop({ required: true })
  readonly refresh!: () => unknown;

  @Prop({ required: true })
  readonly connections!: Connection[];

  readonly coins = coins;
  readonly categorization = categorization;
  readonly directions = directions;
  readonly priorities = priorities;
  readonly comparisonsTypes = comparisonsTypes;
  readonly valueExtractorTypes = valueExtractorTypes;
  readonly assetExtractorTypes = assetExtractorTypes;

  metadata: Metadata[] = [];

  ruleFormData: MakeMaybe<TransferRuleData, 'name' | 'direction'> = {
    id: null,
    disabled: false,
    name: null,
    priority: 1,
    walletId: null,
    coin: null,
    fromAddress: null,
    toAddress: null,
    description: '',
    direction: null,
    autoReconcile: false,
    collapseValues: false,
    autoCategorizeFee: false,
    multiToken: false,
    accountingConnectionId: null,
    methodId: null,
    includesCurrency: null,
  };

  useIdentifiers = false;
  identifierRules = [{ label: 'Contains Coin(s)' }];
  selectedIdentifierRule = this.identifierRules[0];

  action: ActionInput = {
    type: ActionType.SimpleCategorization,
    categoryId: '',
    contactId: '',
    feeContactId: '',
    feeCategoryId: '',
    lines: [],
    feePercentageSplits: [],
    percentageSplits: [],
    deFiWalletId: '',
    ignoreFailPricing: false,
  };

  valueRules: RuleComparisonInput[] = [];

  afterDateISO = '';

  beforeDateISO = '';

  percentageRules = [
    (value: any) => value > 0 || 'Split amount must be greater than 0',
    (value: any) => value <= 100 || 'Split amount must be less than 100',

    (value: any) =>
      this.percentageTotal === 100 || `Percentage splits must add to 100: current total ${this.percentageTotal}`,
  ];

  feePercentageRules = [
    (value: any) => value > 0 || 'Split amount must be greater than 0',
    (value: any) => value <= 100 || 'Split amount must be less than 100',

    (value: any) =>
      this.feePercentageTotal === 100 || `Percentage splits must add to 100: current total ${this.feePercentageTotal}`,
  ];

  splitRules = [(value: unknown) => !!value || 'Value must be defined'];
  data: any;
  @Watch('rule')
  watchRuleChange(newValue: TransferRule | null, oldValue: TransferRule | null) {
    if (newValue && newValue !== oldValue && this.dialog) {
      this.populateForm();
    }
  }

  addSplit(split: number) {
    if (this.action.percentageSplits === undefined) this.action.feePercentageSplits = [];

    this.action.percentageSplits?.push({ percentage: 1 } as PercentageSplitCategorizationInput);
  }

  removeSplit(split: number) {
    this.action.percentageSplits?.splice(split, 1);
  }

  toggleUseIdentifiers() {
    if (this.useIdentifiers) {
      this.ruleFormData.includesCurrency = undefined;
      this.useIdentifiers = false;
    } else {
      this.ruleFormData.includesCurrency = this.rule?.includesCurrency;
      this.useIdentifiers = true;
    }
  }

  addFeeSplit(split: number) {
    if (!this.action.feePercentageSplits) this.action.feePercentageSplits = [];

    this.action.feePercentageSplits?.push({ percentage: 1 } as PercentageSplitCategorizationInput);
  }

  removeFeeSplit(split: number) {
    this.action.feePercentageSplits?.splice(split, 1);
  }

  getRemoteID(item: Contact) {
    const split = item.id.split('.');
    split.shift();
    return split.join('.');
  }

  public getProviderIcon(provider: Providers) {
    return getAccountingProviderIcon(provider);
  }

  get showModal() {
    return this.dialog;
  }

  get percentageTotal() {
    return this.action
      .percentageSplits!.map((split) => parseInt(split.percentage.toString()))
      .reduce((a, c) => (a += c), 0);
  }

  get feePercentageTotal() {
    return this.action
      .feePercentageSplits!.map((split) => parseInt(split.percentage.toString()))
      .reduce((a, c) => (a += c), 0);
  }

  get categories() {
    if (!this.ruleFormData.accountingConnectionId) {
      return [];
    }

    return this.$store.getters['categories/ENABLE_CATEGORIES'].filter(
      (c: Category) => c.accountingConnectionId === this.ruleFormData.accountingConnectionId
    );
  }

  get contacts() {
    if (!this.ruleFormData.accountingConnectionId) {
      return [];
    }
    return this.$store.getters['contacts/ENABLED_CONTACTS'].filter(
      (c: Contact) => c.accountingConnectionId === this.ruleFormData.accountingConnectionId
    );
  }

  get filteredMetadata(): MetadataByType[] {
    if (!this.ruleFormData.accountingConnectionId) {
      return [];
    }

    const m = this.metadata.filter((m) => m.connectionId === this.ruleFormData.accountingConnectionId);

    return groupMetadataByType(m);
  }

  get shouldShowAutoReconcile() {
    if (this.ruleFormData.autoReconcile) {
      return true;
    }
    return this.checkFeatureFlag('rule-auto-reconcile', this.$store.getters.features);
  }

  get wallets() {
    return this.$store.getters['wallets/WALLETS'];
  }

  get deFiWallets() {
    return this.wallets.filter((x: any) => x.type === 22);
  }

  get allowedCategorizationTypes() {
    return this.categorization;
  }

  get validForm() {
    if (!this.ruleFormData.name) {
      return false;
    }

    if (!this.ruleFormData.accountingConnectionId) {
      return false;
    }

    if (!this.ruleFormData.priority) {
      return false;
    }

    if (this.action.type === 'SimpleCategorization') {
      if (!this.action.categoryId || !this.action.contactId) {
        return false;
      }
    }

    if (this.action.type === 'TradeCategorization' || this.action.type === 'DeFiCategorization') {
      if (!this.action.feeContactId) {
        return false;
      }
    }

    if (this.action.type === 'DeFiCategorization') {
      if (!this.action.deFiWalletId) {
        return false;
      }
    }

    // if (this.action.type !== 'DetailedCategorization' && !this.ruleFormData.direction) {
    //   return false;
    // }
    if (this.action.type === 'SimpleSplitCategorization') {
      if (this.percentageTotal !== 100) {
        return false;
      }
      if (this.feePercentageTotal !== 100) {
        return false;
      }
    }
    if (this.action.type === 'DetailedCategorization' && this.action.lines) {
      for (const line of this.action.lines) {
        if (!line.valueExtractor || !line.assetExtractor || !line.contactId || !line.categoryId) return false;
      }
    }

    return true;
  }

  get shouldShowManualAccountingConnection(): boolean {
    const manualContactExist = this.$store.getters['contacts/ENABLED_CONTACTS']?.some(
      (c: Contact) => c.source === 'Manual'
    );
    return (
      manualContactExist ||
      this.$store.getters['categories/ENABLE_CATEGORIES']?.some((c: Category) => c.source === 'Manual')
    );
  }

  get validAccountingConnections() {
    const ac = this.connections.filter((c) => !c.isDisabled && isAccountingProvider(c.provider));

    const manualAccountingConnection: Connection = {
      id: 'Manual',
      provider: 'Bitwave' as any,
      status: ConnectionStatus.Ok,
    };

    if (this.shouldShowManualAccountingConnection) {
      return [manualAccountingConnection].concat(ac);
    }

    return ac;
  }

  get selectedAccountingConnection() {
    return this.validAccountingConnections.find((c) => c.id === this.ruleFormData.accountingConnectionId);
  }

  populateForm() {
    if (this.rule) {
      const {
        type,
        __typename,
        valueRules,
        action,
        beforeDateSEC,
        afterDateSEC,
        includesCurrency,
        ...ruleFormDataInitialValue
      } = this.rule;

      if (afterDateSEC) {
        // this.afterDateISO = moment.unix(afterDateSEC).format("YYYY-MM-DD");
        this.afterDateISO = DateTime.fromSeconds(afterDateSEC, {
          zone: this.$store.state.currentOrg.timezone,
        }).toISODate();
      }

      if (beforeDateSEC) {
        // this.beforeDate = moment.unix(beforeDateSEC).format("YYYY-MM-DD");
        this.beforeDateISO = DateTime.fromSeconds(beforeDateSEC, {
          zone: this.$store.state.currentOrg.timezone,
        }).toISODate();
      }

      //  for backward compatibility
      let accountingConnectionId: string | undefined = ruleFormDataInitialValue.accountingConnectionId ?? undefined;
      if (valueRules) {
        this.valueRules = valueRules.map((rule) => ({
          comparison: rule?.comparison,
          value: rule?.value,
        }));
      }

      if (includesCurrency) {
        this.useIdentifiers = true;
      } else {
        this.useIdentifiers = false;
      }
      this.ruleFormData = {
        ...this.ruleFormData,
        ...ruleFormDataInitialValue,
        includesCurrency,
        accountingConnectionId,
      };
      if (action) {
        const { type } = action;
        if (type === 'Ignore') {
          this.action = {
            type: type,
          };
        }
        if (type === 'InternalTransferCategorization') {
          const temp = action as InternalTransferCategorizationAction;
          this.action = {
            type,
            feeContactId: temp.internalFeeContactId,
            ignoreFailPricing: temp.ignoreFailPricing,
          };
        } else if (type === 'SimpleCategorization') {
          const temp = action as SimpleCategorizationAction;

          //  for backward compatibility, in case we have category and contact but no accountingConnectionId
          if (temp.categoryId && temp.contactId && !ruleFormDataInitialValue.accountingConnectionId) {
            const [accountingConnectionIdFromCat] = temp.categoryId.split('.');
            const [accountingConnectionIdFromCon] = temp.contactId.split('.');
            if (accountingConnectionIdFromCat === accountingConnectionIdFromCon) {
              accountingConnectionId = accountingConnectionIdFromCat;
            }
          }

          this.action = {
            type: type,
            categoryId: temp.categoryId,
            contactId: temp.contactId,
            feeCategoryId: temp.feeCategoryId,
            feeContactId: temp.feeContactId,
            ignoreFailPricing: temp.ignoreFailPricing,
          };
        } else if (type === 'SimpleSplitCategorization') {
          const temp = action as SimpleSplitCategorizationAction;
          this.action = {
            type: type,
            feePercentageSplits: temp.feeSplits,
            percentageSplits: temp.splits,
          };
        } else if (type === 'TradeCategorization') {
          const temp = action as TradeCategorizationAction;

          this.action = {
            type: type,
            feeContactId: temp.tradeFeeContactId,
            ignoreFailPricing: temp.ignoreFailPricing,
          };
        } else if (type === 'DeFiCategorization') {
          const temp = action as DeFiCategorizationAction;

          this.action = {
            type: type,
            feeContactId: temp.deFiFeeContactId,
            deFiWalletId: temp.deFiWalletId,
          };
        } else if (type === 'DetailedCategorization') {
          const lines = (action as DetailedCategorizationAction).lines as DetailedCategorizationActionLineInput[];
          this.action = {
            type: type,
            lines: lines
              ? lines.map((line) => {
                  const { __typename, lineQualifierExtractor, metadataIds, ...otherAttrs } =
                    line as DetailedCategorizationActionLine;
                  const metadataCount = this.metadata.length;
                  const mappedMetadataIds = new Array(metadataCount).fill(null);
                  if (metadataIds) {
                    // iterate through metadataIds passed in by the line
                    metadataIds.forEach((id) => {
                      // iterate throught the metadata count which is the length of this.metadatas
                      let j = -1;
                      for (let i = 0; i < metadataCount; i++) {
                        const subMetadata = this.filteredMetadata[i];
                        if (subMetadata) {
                          const findMetadata = subMetadata.metadata.find((m) => m.id === id);
                          if (findMetadata) {
                            j = i;
                            break;
                          }
                        }
                      }
                      if (j >= 0) {
                        mappedMetadataIds[j] = id;
                      }
                    });
                  }
                  return {
                    ...otherAttrs,
                    lineQualifierExtractor: lineQualifierExtractor ? (lineQualifierExtractor as string) : null,
                    metadataIds: mappedMetadataIds,
                  };
                })
              : lines,
          };
        }
      }
    }
  }

  resetForm() {
    this.ruleFormData = {
      id: null,
      disabled: false,
      name: null,
      priority: 1,
      walletId: null,
      coin: null,
      fromAddress: null,
      toAddress: null,
      description: '',
      direction: null,
      autoReconcile: false,
      collapseValues: false,
      autoCategorizeFee: false,
      multiToken: false,
      accountingConnectionId: null,
      methodId: null,
      includesCurrency: null,
    };

    this.action = {
      type: ActionType.SimpleCategorization,
      categoryId: '',
      contactId: '',
      lines: [],
      percentageSplits: [],
      feePercentageSplits: [],
      ignoreFailPricing: false,
    };

    this.valueRules = [];

    this.afterDateISO = '';

    this.beforeDateISO = '';
  }

  handleAfterDateClear() {
    this.afterDateISO = '';
  }

  handleBeforeDateClear() {
    this.beforeDateISO = '';
  }

  handleAddComparision() {
    this.valueRules.push({
      comparison: undefined,
      value: undefined,
    });
  }

  handleRemoveComparision(index: number) {
    const filteredValueRules = this.valueRules.filter((_, i) => i !== index);
    this.valueRules = filteredValueRules;
  }

  handleCancel() {
    this.resetForm();
    this.hide();
  }

  handleClick() {
    if (!this.rule) {
      this.handleSave();
    } else {
      this.handleUpdate();
    }
  }

  async handleSave() {
    const orgId = this.$store.state.currentOrg.id;
    try {
      const rule = mapTransferRulDataToTransferRuleInput(
        this.ruleFormData as TransferRuleData,
        this.valueRules,
        this.action,
        {
          afterDate: this.afterDateISO,
          beforeDate: this.beforeDateISO,
          timezone: this.$store.state.currentOrg.timezone,
        }
      );
      const resp = await this.$apollo.mutate({
        mutation: gql`
          mutation CreateRule($orgId: ID!, $rule: Rule!) {
            createRule(orgId: $orgId, rule: $rule) {
              success
              errors
            }
          }
        `,
        variables: {
          orgId,
          rule: ruleVarFactory(rule),
        },
      });
      if (resp.data.createRule.success) {
        this.showSnackbar('success', (this.$t('_successRule') as string).replace('[ACTION]', 'Created'));
        this.refresh();
      } else {
        this.showErrorSnackbar('Problem creating rule: ' + resp.data.createRule.errors.join('<br />'));
      }
    } catch (e) {
      this.showSnackbar(
        'error',
        (this.$t('_errorRule') as string).replace('[ACTION]', 'Creating') + ': ' + (e as { message: string })?.message
      );
    } finally {
      this.resetForm();
      if (this.rule) this.reset();
      this.hide();
    }
  }

  async handleUpdate() {
    try {
      const orgId = this.$store.state.currentOrg.id;
      assertDefined(this.rule?.id);
      const ruleId = this.rule.id;
      const rule = mapTransferRulDataToTransferRuleInput(
        this.ruleFormData as TransferRuleData,
        this.valueRules,
        this.action,
        {
          afterDate: this.afterDateISO,
          beforeDate: this.beforeDateISO,
          timezone: this.$store.state.currentOrg.timezone,
        }
      );
      if (this.action.type === 'SimpleSplitCategorization') {
        rule.action.percentageSplits?.map((split) => delete (split as any).__typename);
        rule.action.feePercentageSplits?.map((split) => delete (split as any).__typename);
      }
      const resp = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateRule($orgId: ID!, $ruleId: ID!, $rule: Rule!) {
            updateRule(orgId: $orgId, ruleId: $ruleId, rule: $rule) {
              success
              errors
            }
          }
        `,
        variables: {
          orgId,
          ruleId,
          rule: ruleVarFactory(rule),
        },
      });
      if (resp.data.updateRule.success) {
        this.showSnackbar('success', (this.$t('_successRule') as string).replace('[ACTION]', 'Updated'));
        this.refresh();
      } else {
        this.showErrorSnackbar('Problem updating rule: ' + resp.data.updateRule.errors.join('<br />'));
      }
    } catch (e) {
      this.showSnackbar('error', (this.$t('_errorRule') as string).replace('[ACTION]', 'Updating'));
    } finally {
      this.resetForm();
      if (this.rule) this.reset();
      this.hide();
    }
  }

  showSnackbar(action: string, message: string) {
    this.$store.commit(MUT_SNACKBAR, {
      color: action,
      message,
    });
  }

  hideDialog() {
    this.resetForm();
    this.hide();
  }

  addLine() {
    if (!this.action.lines) this.action.lines = [];
    this.action.lines.push({
      valueExtractor: '' as ValueExtractor,
      assetExtractor: '' as AssetExtractor,
      lineQualifierExtractor: '',
      categoryId: '',
      contactId: '',
      metadataIds: [],
    });
  }

  removeLine(index: number) {
    if (!this.action.lines) return;
    this.action.lines.splice(index, 1);
  }

  includesCurrencyChange(value: string) {
    if (value !== undefined && value !== '') {
      this.ruleFormData.includesCurrency = value.split(',');
    } else {
      this.ruleFormData.includesCurrency = undefined;
    }
  }

  get includesCurrency() {
    return this.ruleFormData.includesCurrency?.join(',');
  }
}
