var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tw-flex tw-flex-row tw-mb-8"},[(_vm.checkScope(_vm.scopeLiterals.RulesCreate))?_c('ui-button',{nativeOn:{"click":function($event){return _vm.openModal()}}},[_vm._v(" Create rule ")]):_vm._e(),_c('ui-button',{staticClass:"tw-ml-auto"},[_c('ui-dropdown',{attrs:{"values":_vm.gridActions,"align":"right"},on:{"select":_vm.onActionClick}},[_c('span',[_vm._v("Actions")]),_c('fa',{staticClass:"tw-ml-3",attrs:{"icon":"fa-solid fa-angle-down"}})],1)],1)],1),_c('ui-data-table',{attrs:{"headers":_vm.headers,"items":_vm.ruleItems,"isLoading":!!_vm.isLoading,"fixed-table":"","resizeable-columns":"","checkable-rows":"","striped":"","showActionColumn":"","no-data-message":"There are no rules to display.","className":"tw-flex-grow tw-font-normal tw-h-full tw-border-t tw-border-300","tbodyClassName":"tw-divide-double tw-divide-gray-300 tw-divide-y tw-text-gray-500"},on:{"sort":_vm.onSort,"selection-changed":_vm.onSelectionChanged},scopedSlots:_vm._u([{key:"td-priority",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col tw-truncate cell"},[_vm._v(" "+_vm._s(item.priority)+" ")])])]}},{key:"td-name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col tw-truncate cell"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"td-actionType",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col tw-truncate cell"},[_vm._v(" "+_vm._s(_vm.getRuleActionV2(item))+" ")])])]}},{key:"td-walletId",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col tw-truncate cell"},[_vm._v(" "+_vm._s(_vm.getWalletName(item.walletId))+" ")])])]}},{key:"td-coin",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col tw-truncate cell"},[_vm._v(" "+_vm._s(item.coin)+" ")])])]}},{key:"td-disabled",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col tw-truncate cell"},[(_vm.checkScope(_vm.scopeLiterals.RulesUpdate))?_c('div',{staticClass:"tw-ml-1"},[_c('ui-toggle',{attrs:{"value":!item.disabled},on:{"input":function($event){return _vm.onStatusToggle(item, $event)}}})],1):_c('div',[_vm._v(" "+_vm._s(item.disabled ? 'Disabled' : 'Enabled')+" ")])])])]}},{key:"td-action",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col tw-truncate cell tw-text-center"},[_c('a',{on:{"click":function($event){return _vm.openModal(item)}}},[_c('fa',{staticClass:"tw-ml-3",attrs:{"icon":"fa-solid fa-pencil"}})],1)])])]}}])}),(_vm.checkScope(_vm.scopeLiterals.RulesCreate))?_c('rule-modal-2',{attrs:{"open":_vm.isModalOpen,"rule":_vm.selectedRule,"refresh":_vm.refresh,"connections":_vm.connections},on:{"close":_vm.closeModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }