

















import axios from 'axios';
import { Component, Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';

import { baConfig } from '../../../config';
import UiTextEdit from '../ui/UiTextEdit.vue';

@Component({
  components: { UiTextEdit },
})
export default class RuleValidation extends BaseVue {
  @Prop()
  readonly rule!: { id: string };

  valdiateTxnId = '';
  validating = false;
  validated = false;
  valid = false;

  getValidation() {
    if (this.validating) {
      return 'tw-bg-gray-500';
    }
    if (this.validated && !this.valid) {
      return 'tw-bg-red-500';
    }
    return 'tw-bg-green-500';
  }

  async handleValidate() {
    try {
      this.validated = false;
      this.validating = true;
      const orgId = this.$store.state.currentOrg.id;
      const url = `${baConfig.apiUrl}orgs/${orgId}/transactions/${this.valdiateTxnId}/rules/${this.rule?.id}`;
      const validationResp = (await axios({ url, withCredentials: true })).data;
      if (validationResp.result.applied) {
        this.valid = true;
        this.showSuccessSnackbar('Rule validated successfully');
      } else {
        this.valid = false;
        this.showErrorSnackbar(
          `Rule Validation failed due to error(s):- ${validationResp.result.error.split(';').join(', ')}`
        );
      }
      this.validated = true;
    } catch (error) {
      this.validated = true;
      this.valid = false;
      this.showErrorSnackbar(`Problem validating rule, Please try again later. Error - ${error}`);
      throw error;
    } finally {
      this.validating = false;
    }
  }
}
