
















import gql from 'graphql-tag';
import Component from 'vue-class-component';

import { Connection } from '@/api-svc-types';

import { BaseVue } from '../../BaseVue';
import RulesGrid from '../../components/rules/RulesGrid.vue';
import RulesGrid2 from '../../components/rules/RulesGrid2.vue';

@Component({
  components: {
    RulesGrid,
    RulesGrid2,
  },
  apollo: {
    connections: {
      query: gql`
        query GetConnections($orgId: ID!) {
          connections(orgId: $orgId, overrideCache: true) {
            id
            provider
            lastSyncSEC
            isSetupComplete
            isDisabled
            isDeleted
            syncStatus {
              status
              lastSyncCompletedSEC
              errors
              warnings
              isRunning
            }
            name
            accountCode
            feeAccountCode
            connectionSpecificFields
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
    },
  },
})
export default class Rules extends BaseVue {
  public selectedTab = 0;

  connections: Connection[] = [];
}
