































































































import gql from 'graphql-tag';
import Component from 'vue-class-component';
import { TranslateResult } from 'vue-i18n';
import { Prop, Watch } from 'vue-property-decorator';

import { ActionType, Connection, InputMaybe, TransferRuleInput, Wallet } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import { MUT_SNACKBAR } from '@/store';

import UiButton from '../ui/UiButton.vue';
import UiDataTable from '../ui/UiDataTable.vue';
import UiDropdown from '../ui/UiDropdown.vue';
import UiToggle from '../ui/UiToggle.vue';
import RuleModal2 from './RuleModal2.vue';

@Component({
  components: {
    UiDataTable,
    UiToggle,
    UiButton,
    UiDropdown,
    RuleModal2,
  },
  apollo: {
    $client: 'rptApolloClient',
    rules: {
      query: gql`
        query rules($orgId: ID!) {
          rules(orgId: $orgId) {
            id
            name
            disabled
            type
            priority
            methodId
            action {
              ... on DetailedCategorizationAction {
                lines {
                  valueExtractor
                  assetExtractor
                  lineQualifierExtractor
                  contactId
                  categoryId
                  metadataIds
                }
                type
                ignoreFailPricing
              }
              ... on IgnoreAction {
                type
              }
              ... on SimpleCategorizationAction {
                type
                contactId
                categoryId
                feeContactId
                feeCategoryId
                ignoreFailPricing
              }
              ... on InternalTransferCategorizationAction {
                type
                internalFeeContactId: feeContactId
                ignoreFailPricing
              }
              ... on SimpleSplitCategorizationAction {
                type
                splits {
                  ... on PercentageSplit {
                    percentage
                    contactId
                    categoryId
                  }
                }
                feeSplits {
                  ... on PercentageSplit {
                    percentage
                    contactId
                    categoryId
                  }
                }
              }
              ... on TradeCategorizationAction {
                type
                tradeFeeContactId: feeContactId
                ignoreFailPricing
              }
              ... on DeFiCategorizationAction {
                type
                deFiFeeContactId: feeContactId
                deFiWalletId
              }
            }
            coin
            description
            fromAddress
            toAddress
            valueRules {
              comparison
              value
            }
            afterDateSEC
            beforeDateSEC
            walletId
            direction
            autoReconcile
            collapseValues
            autoCategorizeFee
            multiToken
            accountingConnectionId
            includesCurrency
            metadataRule {
              operator
              metadata {
                key
                value
              }
              txnRecordRule
            }
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
      fetchPolicy: 'network-only',
    },
    connections: {
      query: gql`
        query GetConnections($orgId: ID!) {
          connections(orgId: $orgId, overrideCache: true) {
            id
            provider
            lastSyncSEC
            isSetupComplete
            isDisabled
            isDeleted
            syncStatus {
              status
              lastSyncCompletedSEC
              errors
              warnings
              isRunning
            }
            name
            accountCode
            feeAccountCode
            connectionSpecificFields
            isDefault
            status
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
      fetchPolicy: 'network-only',
    },
  },
})
export default class RulesGrid2 extends BaseVue {
  @Prop({ default: [] })
  connections!: Connection[];

  public rules: TransferRuleInput[] = [];
  public rulesWithValidConnection: TransferRuleInput[] = [];
  public ruleItems: TransferRuleInput[] = [];
  public sort?: { asc: boolean; id: keyof TransferRuleInput };
  public gridActions: { value: string; label: string; disabled?: boolean }[] = [];
  public selectedRule: TransferRuleInput | null = null;
  public selectedRules: TransferRuleInput[] = [];
  public isLoading = 0;
  public isModalOpen = false;

  mounted() {
    this.setGridActions();
  }

  @Watch('rules')
  async rulesChange() {
    await this.$apollo.queries.connections.refetch();
    const disabledConnectionIds = this.connections
      .filter((c) => {
        return c.isDisabled;
      })
      .map((c) => {
        return c.id;
      });

    this.rulesWithValidConnection = this.rules.filter((rule) => {
      return (
        rule.accountingConnectionId === undefined ||
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        disabledConnectionIds.indexOf(rule.accountingConnectionId!) === -1
      );
    });

    this.$set(this, 'ruleItems', [...this.rulesWithValidConnection]);
    this._sortRules();
  }

  @Watch('$store.state.scopes')
  onScopeChange() {
    this.setGridActions();
  }

  public setGridActions() {
    this.gridActions = [];
    if (this.checkScope(this.scopeLiterals.RulesDelete)) {
      this.gridActions.push({ value: 'delete', label: this.$tc('_delete') });
    }

    if (this.checkScope(this.scopeLiterals.RulesUpdate)) {
      this.gridActions.push({ value: 'disable', label: this.$tc('_disable') });
      this.gridActions.push({ value: 'enable', label: this.$tc('_enable') });
    }

    if (this.checkScope(this.scopeLiterals.TransactionCategorizeUpdate)) {
      this.gridActions.push({ value: 'run', label: this.$tc('_run') });
    }
  }

  public get headers() {
    return [
      {
        id: 'priority',
        label: this.$t('_priority'),
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'name',
        label: this.$t('_ruleName'),
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'actionType',
        label: this.$t('_type'),
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'walletId',
        label: this.$t('_wallet'),
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'coin',
        label: 'Asset',
        defaultVisibility: true,
        sortable: true,
      },
      {
        id: 'disabled',
        label: this.$t('_status'),
        defaultVisibility: true,
      },
    ];
  }

  public get wallets() {
    return this.$store.getters['wallets/WALLETS'];
  }

  public refresh() {
    this.$apollo.queries.rules.refetch();
  }

  public onActionClick(action: string) {
    switch (action) {
      case 'delete':
        this._bulkDelete();
        break;
      case 'disable':
        this._bulkDisable();
        break;
      case 'enable':
        this._bulkEnable();
        break;
      case 'run':
        this._runRules();
        break;
    }
  }

  public onSort(sort: { asc: boolean; id: keyof TransferRuleInput }) {
    this.sort = sort;
    this._sortRules();
  }

  public onSelectionChanged(selections: TransferRuleInput[]) {
    this.$set(this, 'selectedRules', selections);
  }

  public async onStatusToggle(rule: TransferRuleInput, value: boolean) {
    if (rule.disabled === !value || !this.checkScope(this.scopeLiterals.RulesUpdate)) return;

    const idx = this.ruleItems.findIndex((x) => x.id === rule.id);

    try {
      const response = await this._changeRulesStatus([rule.id], !value);
      if (response && !response.find((x: any) => x.error)) {
        this._showSnackbar('success', this.$t('_successUpdate'));
        this.ruleItems[idx].disabled = !value;
      } else {
        const baseStringError = 'GraphQL error:';
        const errors = response
          .map((x: any) => x.error.substr(x.error.indexOf(baseStringError) + baseStringError.length).trim())
          .join('\n');
        this._showSnackbar('error', errors);
        this.ruleItems[idx].disabled = value;
      }
    } catch (e) {
      console.error(e);
      this._showSnackbar('error', this.$t('_errorUpdate'));
      this.ruleItems[idx].disabled = value;
    }
  }

  public openModal(rule?: TransferRuleInput) {
    this.selectedRule = rule ?? null;
    this.isModalOpen = true;
  }

  public closeModal() {
    this.isModalOpen = false;
    this.selectedRule = null;
  }

  public getWalletName(walletId?: InputMaybe<string>) {
    if (!walletId) return '';

    const wallet = this.wallets?.find((wallet: Wallet) => wallet.id === walletId);
    if (wallet && wallet.name) return wallet.name;
    return walletId;
  }

  public getRuleActionV2(rule: TransferRuleInput) {
    const actionTypeMapping: { [key in ActionType]: string } = {
      Ignore: 'Ignore',
      SimpleCategorization: 'Categorize',
      InternalTransferCategorization: 'Categorize: Internal Transfers',
      TradeCategorization: 'Categorize: Trades',
      DeFiCategorization: 'Advance Categorize: DeFi',
      SimpleSplitCategorization: 'Advance Categorize: Split',
      DetailedCategorization: 'Advance Categorize: Detailed',
    };
    return actionTypeMapping[rule.action.type];
  }

  private _sortRules() {
    if (!this.sort || !this.sort.id) {
      this.$set(this, 'ruleItems', [...this.rulesWithValidConnection]);
      return;
    }

    const sortValue = (rule: TransferRuleInput, sortKey: keyof TransferRuleInput) => {
      if (sortKey === 'walletId') return this.getWalletName(rule.walletId);
      return rule[sortKey] ?? '';
    };

    const sorted = [...this.ruleItems].sort((a: TransferRuleInput, b: TransferRuleInput) => {
      const aVal = sortValue(a, this.sort!.id);
      const bVal = sortValue(b, this.sort!.id);
      if ((aVal > bVal && this.sort?.asc) || (aVal < bVal && !this.sort?.asc)) return 1;
      if ((aVal < bVal && this.sort?.asc) || (aVal > bVal && !this.sort?.asc)) return -1;
      return 0;
    });

    this.$set(this, 'ruleItems', sorted);
  }

  private async _bulkDelete() {
    // security measure in case the action was displayed for any reason
    if (!this.checkScope(this.scopeLiterals.RulesDelete)) return;
    if (this.selectedRules.length < 1) {
      this._showSnackbar('error', 'No rules are selected');
      return;
    }

    try {
      const ids = this.selectedRules.map((x) => x.id);
      await this._deleteRules(ids);
      this._showSnackbar('success', this.$tc('_successBulkDelete'));
    } catch (e) {
      this._showSnackbar('error', this.$tc('_errorBulkDelete'));
    } finally {
      this.refresh();
      this.selectedRules = [];
    }
  }

  private _deleteRules(rulesId: (InputMaybe<string> | undefined)[]) {
    const rulesArr = rulesId.map((r) => {
      const orgId = this.$store.state.currentOrg.id;
      const ruleId = r;
      return this.$apollo.mutate({
        mutation: gql`
          mutation DeleteRule($orgId: ID!, $ruleId: ID!) {
            deleteRule(orgId: $orgId, ruleId: $ruleId)
          }
        `,
        variables: {
          orgId,
          ruleId,
        },
      });
    });
    return Promise.all(rulesArr);
  }

  private async _bulkEnable() {
    // security measure in case the action was displayed for any reason
    if (!this.checkScope(this.scopeLiterals.RulesUpdate)) return;
    if (this.selectedRules.length < 2) {
      this._showSnackbar('error', 'Two or more rules should be selected');
      return;
    }

    try {
      const disabled = false;
      const ids = this.selectedRules.map((x) => x.id);
      const response = await this._changeRulesStatus(ids, disabled);
      const errored = response.filter((x: any) => x.error);
      if (errored && errored.length > 0) {
        const message = errored.length !== ids.length ? '_someErrorBulkEnable' : '_errorBulkEnable';
        this._showSnackbar('error', this.$tc(message));
      } else {
        this._showSnackbar('success', this.$tc('_successBulkEnable'));
      }
      this.refresh();
      this.selectedRules = [];
    } catch (e) {
      this._showSnackbar('error', this.$tc('_errorBulkEnable'));
      this.refresh();
    } finally {
      this.selectedRules = [];
    }
  }

  private async _bulkDisable() {
    // security measure in case the action was displayed for any reason
    if (!this.checkScope(this.scopeLiterals.RulesUpdate)) return;
    if (this.selectedRules.length < 2) {
      this._showSnackbar('error', 'Two or more rules should be selected');
      return;
    }

    try {
      const disabled = true;
      const ids = this.selectedRules.map((x) => x.id);
      await this._changeRulesStatus(ids, disabled);
      this.refresh();
      this._showSnackbar('success', this.$t('_successBulkDisable'));
    } catch (e) {
      this._showSnackbar('error', this.$t('_errorBulkDisable'));
      this.selectedRules = [];
    }
    this.selectedRules = [];
  }

  private _changeRulesStatus(rulesId: (InputMaybe<string> | undefined)[], status: boolean) {
    const rulesArr = rulesId.map((ruleId) => {
      const orgId = this.$store.state.currentOrg.id;
      return this.$apollo
        .mutate({
          mutation: gql`
            mutation ToggleRuleStatus($orgId: ID!, $ruleId: ID!, $disabled: Boolean!) {
              toggleRuleStatus(orgId: $orgId, ruleId: $ruleId, disabled: $disabled)
            }
          `,
          variables: {
            orgId,
            ruleId,
            disabled: status,
          },
        })
        .catch((error) => {
          return { error: error.message };
        });
    });
    return Promise.all(rulesArr);
  }

  private async _runRules() {
    // security measure in case the action was displayed for any reason
    if (!this.checkScope(this.scopeLiterals.TransactionCategorizeUpdate)) return;

    const orgId = this.$store.state.currentOrg.id;
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation RunRulesForOrg($orgId: ID!) {
          runRulesForOrg(orgId: $orgId)
        }
      `,
      variables: {
        orgId,
      },
    });
    if (res.errors) {
      this._showSnackbar('error', this.$t('_errorRunRules'));
    } else {
      this._showSnackbar('success', this.$t('_successRunRules'));
    }
  }

  private _showSnackbar(action: string, message: string | TranslateResult) {
    this.$store.commit(MUT_SNACKBAR, {
      color: action,
      message,
    });
  }
}
