<template>
  <v-layout row wrap>
    <rule-modal-2
      v-if="checkScope(scopeLiterals.RulesCreate) && checkFeatureFlag('rules-modal-2')"
      :open="dialog"
      :rule="selectedRule"
      :refresh="refresh"
      :connections="connections"
      @close="hideDialog"
    ></rule-modal-2>
    <v-flex style="margin: 24px 0 16px 0; width: 100%; display: flex; justify-content: space-between">
      <v-btn
        v-if="checkScope(scopeLiterals.RulesCreate) && checkFeatureFlag('rules-modal-2')"
        @click="dialog = true"
        color="success"
        :small="true"
        >Create Rule</v-btn
      >
      <create-rule-modal
        :dialog="dialog"
        :hide="hideDialog"
        :show="showDialog"
        :rule="selectedRule"
        :reset="resetSelectedRule"
        :refresh="refresh"
        :connections="connections"
        v-if="checkScope(scopeLiterals.RulesCreate) && !checkFeatureFlag('rules-modal-2')"
      />
      <v-menu offset-y>
        <v-btn slot="activator" color="success" dark :small="true" colo class="secondary">
          {{ $tc('_action', 2) }}
          <i class="material-icons"> arrow_drop_down </i>
        </v-btn>
        <v-list>
          <v-list-tile @click="bulkDelete" :disabled="selectedCount < 1" v-if="checkScope(scopeLiterals.RulesDelete)">
            <v-list-tile-title>{{ $tc('_delete') }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="bulkDisable" :disabled="selectedCount < 2" v-if="checkScope(scopeLiterals.RulesUpdate)">
            <v-list-tile-title>{{ $tc('_disable') }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="bulkEnable" :disabled="selectedCount < 2" v-if="checkScope(scopeLiterals.RulesUpdate)">
            <v-list-tile-title>{{ $tc('_enable') }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="runRules" v-if="checkScope(scopeLiterals.TransactionCategorizeUpdate)">
            <v-list-tile-title>{{ $tc('_run') }}</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-flex>
    <v-flex xs12>
      <v-data-table
        v-model="selectedRules"
        :headers="headers"
        :items="rules"
        item-key="id"
        select-all
        :loading="isLoading ? 'accent' : false"
      >
        <template v-slot:headers="props">
          <tr>
            <th>
              <v-checkbox
                :input-value="props.all"
                :indeterminate="props.indeterminate"
                primary
                hide-details
                @click.stop="toggleAll"
              ></v-checkbox>
            </th>
            <th v-for="header in props.headers" :key="header.text">
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:items="props">
          <tr>
            <td :active="props.selected" @click="props.selected = !props.selected">
              <v-checkbox style="margin: 0px; padding: 0px" :input-value="props.selected" primary hide-details />
            </td>
            <td>{{ props.item.priority }}</td>
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.action.type }}</td>
            <td>
              <span v-if="existWallet(props.item.walletId) === false">
                <v-tooltip bottom>
                  <template slot="activator">
                    <v-icon color="warning">warning</v-icon>
                  </template>
                  <span>Wallet not found</span>
                </v-tooltip>
              </span>
              <span v-else>
                {{ getWalletName(props.item.walletId, props.item.id) }}
              </span>
            </td>
            <td>
              {{
                props.item.action && props.item.action.type === 'SimpleCategorization'
                  ? getContactName(props.item.action.contactId)
                  : ''
              }}
            </td>
            <td>{{ props.item.coin }}</td>
            <td>
              <v-switch
                :v-model="!props.item.disabled"
                :input-value="!props.item.disabled"
                :value="props.item.disabled"
                :key="reloadComponent"
                @change="(value) => handleChangeStatus(props.item, value)"
                v-if="checkScope(scopeLiterals.RulesUpdate)"
              />
            </td>
            <td>
              <v-icon @click="handleEditRule(props.item)" v-if="checkScope(scopeLiterals.RulesUpdate)">edit</v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import gql from 'graphql-tag';

import { MUT_SNACKBAR } from '../../store';
import CreateRuleModal from './CreateRuleModal.vue';
import RuleModal2 from './RuleModal2.vue';

export default {
  props: ['contacts', 'connections'],
  components: {
    CreateRuleModal,
    RuleModal2,
  },
  data() {
    return {
      isLoading: 0,
      dialog: false,
      selectedRule: null,
      headers: [
        {
          text: this.$t('_priority'),
          align: 'left',
          value: 'priority',
        },
        {
          text: this.$t('_ruleName'),
          align: 'left',
          value: 'name',
        },
        {
          text: this.$tc('_type'),
          align: 'left',
          value: 'type',
        },
        {
          text: this.$tc('_wallet'),
          align: 'left',
          value: 'walletId',
        },
        {
          text: this.$t('_company'),
          align: 'left',
          value: 'action.contactId',
        },
        {
          text: this.$tc('_crypto'),
          align: 'left',
          value: 'coin',
        },
        {
          text: this.$tc('_status'),
          align: 'left',
          value: 'disabled',
        },
      ],
      selectedRules: [],
      pageLimit: 1,
      reloadComponent: 0,
    };
  },
  apollo: {
    $client: 'rptApolloClient',
    rules: {
      query: gql`
        query rules($orgId: ID!) {
          rules(orgId: $orgId) {
            id
            name
            disabled
            type
            priority
            methodId
            action {
              ... on DetailedCategorizationAction {
                lines {
                  valueExtractor
                  assetExtractor
                  lineQualifierExtractor
                  contactId
                  categoryId
                  metadataIds
                }
                type
              }
              ... on IgnoreAction {
                type
              }
              ... on SimpleCategorizationAction {
                type
                contactId
                categoryId
                feeContactId
                feeCategoryId
                ignoreFailPricing
              }
              ... on InternalTransferCategorizationAction {
                type
                internalFeeContactId: feeContactId
                ignoreFailPricing
              }
              ... on SimpleSplitCategorizationAction {
                type
                splits {
                  ... on PercentageSplit {
                    percentage
                    contactId
                    categoryId
                  }
                }
                feeSplits {
                  ... on PercentageSplit {
                    percentage
                    contactId
                    categoryId
                  }
                }
              }
              ... on TradeCategorizationAction {
                type
                tradeFeeContactId: feeContactId
                ignoreFailPricing
              }
              ... on DeFiCategorizationAction {
                type
                deFiFeeContactId: feeContactId
                deFiWalletId
              }
            }
            coin
            description
            fromAddress
            toAddress
            valueRules {
              comparison
              value
            }
            afterDateSEC
            beforeDateSEC
            walletId
            direction
            autoReconcile
            collapseValues
            autoCategorizeFee
            multiToken
            accountingConnectionId
            includesCurrency
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      // update(data) {
      //     data.rules.forEach(x=> {
      //       if (x.action?.tradeFeeContactId)
      //         x.action.feeContactId = x.action.tradeFeeContactId})
      //   return data;
      // },
      loadingKey: 'isLoading',
    },
  },
  methods: {
    toggleAll() {
      if (this.selectedRules.length > 0) this.selectedRules = [];
      else this.selectedRules = this.rules.slice();
    },
    existWallet(walletId) {
      if (!walletId) return true;
      if (!this.wallets) return false;
      const wallet = this.wallets.find((wallet) => wallet.id === walletId);
      if (!wallet) return false;
      return true;
    },
    getWalletName(walletId) {
      if (!walletId) {
        return '';
      }
      const wallet = this.wallets.find((wallet) => wallet.id === walletId);
      if (wallet && wallet.name) return wallet.name;
      return walletId;
    },
    getContactName(contactId) {
      const contact = this.contactsStored.find((contact) => contact.id === contactId);
      if (!contact) {
        return '';
      }
      return contact && contact.name ? contact.name : contactId;
    },
    handleEditRule(item) {
      this.selectedRule = item;
      this.showDialog();
    },
    async handleChangeStatus(item, value) {
      const idx = this.rules.findIndex((x) => x.id === item.id);
      try {
        const response = await this.changeRulesStatus([item.id], !value);
        if (response && !response.find((x) => x.error)) {
          this.showSnackbar('success', this.$t('_successUpdate'));
          this.rules[idx].disabled = !value;
        } else {
          const baseStringError = 'GraphQL error:';
          const errors = response
            .map((x) => x.error.substr(x.error.indexOf(baseStringError) + baseStringError.length).trim())
            .join('\n');
          this.showSnackbar('error', errors);
          this.rules[idx].disabled = value;
          this.reloadSwitch();
        }
      } catch (e) {
        console.log(e);
        this.showSnackbar('error', this.$t('_errorUpdate'));
        this.rules[idx].disabled = value;
        this.reloadSwitch();
      }
    },
    async bulkDelete() {
      try {
        const ids = this.selectedRules.map((x) => x.id);
        await this.deleteRules(ids);
        this.showSnackbar('success', this.$tc('_successBulkDelete'));
      } catch (e) {
        this.showSnackbar('error', this.$tc('_errorBulkDelete'));
      } finally {
        this.refresh();
        this.selectedRules = [];
      }
    },
    async bulkEnable() {
      try {
        const disabled = false;
        const ids = this.selectedRules.map((x) => x.id);
        const response = await this.changeRulesStatus(ids, disabled);
        const errored = response.filter((x) => x.error);
        if (errored && errored.length > 0) {
          const message = errored.length !== ids.length ? '_someErrorBulkEnable' : '_errorBulkEnable';
          this.showSnackbar('error', this.$tc(message));
        } else {
          this.showSnackbar('success', this.$tc('_successBulkEnable'));
        }
        this.refresh();
        this.reloadSwitch();
        this.selectedRules = [];
      } catch (e) {
        this.showSnackbar('error', this.$tc('_errorBulkEnable'));
        this.refresh();
        this.reloadSwitch();
      } finally {
        this.selectedRules = [];
      }
    },
    async bulkDisable() {
      try {
        const disabled = true;
        const ids = this.selectedRules.map((x) => x.id);
        await this.changeRulesStatus(ids, disabled);
        this.refresh();
        this.showSnackbar('success', this.$t('_successBulkDisable'));
      } catch (e) {
        this.showSnackbar('error', this.$t('_errorBulkDisable'));
        this.selectedRules = [];
      }
      this.selectedRules = [];
    },
    deleteRules(rulesId) {
      const rulesArr = rulesId.map((r) => {
        const orgId = this.$store.state.currentOrg.id;
        const ruleId = r;
        return this.$apollo.mutate({
          mutation: gql`
            mutation DeleteRule($orgId: ID!, $ruleId: ID!) {
              deleteRule(orgId: $orgId, ruleId: $ruleId)
            }
          `,
          variables: {
            orgId,
            ruleId,
          },
        });
      });
      return Promise.all(rulesArr);
    },
    changeRulesStatus(rulesId, status) {
      const rulesArr = rulesId.map((ruleId) => {
        const orgId = this.$store.state.currentOrg.id;
        return this.$apollo
          .mutate({
            mutation: gql`
              mutation ToggleRuleStatus($orgId: ID!, $ruleId: ID!, $disabled: Boolean!) {
                toggleRuleStatus(orgId: $orgId, ruleId: $ruleId, disabled: $disabled)
              }
            `,
            variables: {
              orgId,
              ruleId,
              disabled: status,
            },
          })
          .catch((error) => {
            return { error: error.message };
          });
      });
      return Promise.all(rulesArr);
    },
    async runRules() {
      const orgId = this.$store.state.currentOrg.id;
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation RunRulesForOrg($orgId: ID!) {
            runRulesForOrg(orgId: $orgId)
          }
        `,
        variables: {
          orgId,
        },
      });
      if (res.errors) {
        this.showSnackbar('error', this.$t('_errorRunRules'));
      } else {
        this.showSnackbar('success', this.$t('_successRunRules'));
      }
    },
    showSnackbar(action, message) {
      this.$store.commit(MUT_SNACKBAR, {
        color: action,
        message,
      });
    },
    refresh() {
      this.$apollo.queries.rules.refetch();
    },
    showDialog() {
      this.dialog = true;
    },
    hideDialog() {
      this.dialog = false;
      this.resetSelectedRule();
    },
    resetSelectedRule() {
      this.selectedRule = null;
    },
    reloadSwitch() {
      this.reloadComponent += 1;
    },
  },
  computed: {
    selectedCount() {
      if (this.selectedRules && this.selectedRules.length) {
        return this.selectedRules.length;
      } else {
        return 0;
      }
    },
    wallets() {
      return this.$store.getters['wallets/WALLETS'];
    },
    contactsStored() {
      return this.$store.getters['contacts/ENABLED_CONTACTS'];
    },
  },
};
</script>
